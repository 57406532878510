import { Link } from "gatsby";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { buildImageObj, getSpotlightUrl } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BrandColorsHorizontalDivider from "./BrandColorsHorizontalDivider";
import PortableText from "./portableText";

function SpotlightPreview(props) {
  return (
    <>
      <Card className="mb-3 py-4">
        <Card.Body>
          <Row className="d-flex align-items-center">
            <Col xs={12} sm={5} md={4}>
              <Link to={props.network.slug ? getSpotlightUrl(props.network.slug.current) : ""}>
                {props.network._rawLogo && props.network._rawLogo.asset && (
                  <img
                    src={imageUrlFor(buildImageObj(props.network._rawLogo))
                      .auto("format")
                      .url()}
                    className="img-fluid mb-2 mb-sm-0 mx-sm-auto d-block"
                    style={{ width: 150 }}
                  />
                )}
              </Link>
            </Col>
            <Col xs={12} sm={7} md={8}>
              <Link
                to={props.network.slug ? getSpotlightUrl(props.network.slug.current) : ""}
                className="text-decoration-none"
              >
                <h4>{props.network.title && props.network.title}</h4>
              </Link>
              {props.intro && (
                <div className="mb-1">
                  <PortableText blocks={props.intro._rawSimpleWhatItIs} />
                </div>
              )}
              <div className="mb-3">
                <Link to={`/network/${props.network.slug.current}`}>Learn more →</Link>
              </div>
              <BrandColorsHorizontalDivider brandColors={props.network.brandColors} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

export default SpotlightPreview;
