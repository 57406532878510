import { Link } from "gatsby";
import React from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import { buildImageObj, getSpotlightUrl } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import BrandColorsHorizontalDivider from "./BrandColorsHorizontalDivider";
import PortableText from "./portableText";
import SpotlightPreview from "./spotlight-preview";

function SpotlightPreviewGrid(props) {
  return (
    <div className="mb-5">
      {(props.listType === "panel" || !props.listType) &&
        props.nodes &&
        props.nodes.map(node => (
          <SpotlightPreview key={node.id} listType={props.listType} {...node} isInList />
        ))}
      {props.listType === "group" && (
        <ListGroup>
          {props.nodes &&
            props.nodes
              .filter(node => node.ready)
              .map(node => (
                <SpotlightPreview key={node.id} listType={props.listType} {...node} isInList />
              ))}
        </ListGroup>
      )}
      {props.listType === "grid" && props.nodes && (
        <Row>
          {props.nodes.map(node => (
            <Col md="6" className="mb-3">
              <Card>
                <Card.Body className="pt-0">
                  <Link
                    className="text-decoration-none"
                    to={node.network.slug ? getSpotlightUrl(node.network.slug.current) : ""}
                  >
                    <div>
                      <Row className="d-flex align-items-center">
                        <Col>
                          {node.network._rawLogo && node.network._rawLogo.asset && (
                            <img
                              src={imageUrlFor(buildImageObj(node.network._rawLogo))
                                .auto("format")
                                .url()}
                              className="d-block my-3"
                              style={{ width: 150 }}
                            />
                          )}
                        </Col>
                        <Col>
                          <h6 className="mb-0 text-right">{node.network.title}</h6>
                        </Col>
                      </Row>
                      <BrandColorsHorizontalDivider brandColors={node.network.brandColors} />
                      {node.intro && node.intro._rawSimpleWhatItIs && (
                        <div>
                          <PortableText blocks={node.intro._rawSimpleWhatItIs} />
                        </div>
                      )}
                    </div>
                  </Link>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
}

SpotlightPreviewGrid.defaultProps = {
  title: "",
  nodes: []
};

export default SpotlightPreviewGrid;
